import Field from "@farmact/model/src/model/Field";
import { Machine } from "@farmact/model/src/model/Machine";
import { Order, TaskRecord } from "@farmact/model/src/model/Order";
import { ServicePriceUnit } from "@farmact/model/src/model/services/ServicePriceUnit";
import { TaskRecordType } from "@farmact/model/src/model/services/TaskRecords";
import {
    AnyTimeTrackingMapStructure,
    TimeTracking,
    TimeTrackingMapStructureType,
} from "@farmact/model/src/model/TimeTracking";
import { Track } from "@farmact/model/src/model/Track";
import { round } from "@farmact/utils/src/numberUtils";
import { getDocs, query, where } from "firebase/firestore";
import { computeLength } from "spherical-geometry-js";
import { v4 } from "uuid";
import { getAreaUnit, getTracksUnit } from "@/components/orders/taskRecords/taskRecordModalContentUtils";
import { Firebase } from "@/firebase";
import { mergeQueryData } from "@/firebase/helpers";
import { getModelConverter } from "@/firebase/ModelConverter";
import { getCurrentlyActiveRunId } from "@/util/orderUtils";
import { constructMachineVariantsForNewOrderDataEntry } from "./constructMachineVariantsForNewOrderDataEntry";
import { getCurrentlyActiveCustomerId } from "./orderUtils";

async function getTimeTrackingsForLastOrderRun(order: Order): Promise<TimeTracking[]> {
    const lastActiveTime = order.activeTime[order.activeTime.length - 1];
    return (
        await getDocs(
            Firebase.instance()
                .getAllTimeTrackingsForOrderRunId(order.id, lastActiveTime.id)
                .withConverter(getModelConverter(TimeTracking))
        )
    ).docs.map(doc => doc.data());
}

async function loadRelevantFields(
    order: Order,
    currentlyActiveMapStructure: AnyTimeTrackingMapStructure | null
): Promise<Field[]> {
    const timeTrackingsForOrderRunId = await getTimeTrackingsForLastOrderRun(order);
    const uniqueFieldIds = new Set<Field["id"]>();
    timeTrackingsForOrderRunId.forEach(timeTracking => {
        if (timeTracking.order?.mapStructure?.type === TimeTrackingMapStructureType.FIELD) {
            uniqueFieldIds.add(timeTracking.order?.mapStructure?.fieldId);
        }
    });
    if (currentlyActiveMapStructure?.type === TimeTrackingMapStructureType.FIELD) {
        uniqueFieldIds.add(currentlyActiveMapStructure.fieldId);
    }

    if (uniqueFieldIds.size === 0) {
        return mergeQueryData(order.fieldIds, ids => {
            return query(Firebase.instance().getAllFields({ companyId: undefined }), where("id", "in", ids));
        });
    } else {
        return mergeQueryData(Array.from(uniqueFieldIds), ids => {
            return query(Firebase.instance().getAllFields({ companyId: undefined }), where("id", "in", ids));
        });
    }
}

async function loadRelevantOrderTracks(
    order: Order,
    currentlyActiveMapStructure: AnyTimeTrackingMapStructure | null
): Promise<Track[]> {
    const timeTrackingsForOrderRunId = await getTimeTrackingsForLastOrderRun(order);
    const uniqueTrackIds = new Set<Track["id"]>();
    timeTrackingsForOrderRunId.forEach(timeTracking => {
        if (timeTracking.order?.mapStructure?.type === TimeTrackingMapStructureType.TRACK) {
            uniqueTrackIds.add(timeTracking.order?.mapStructure?.trackId);
        }
    });
    if (currentlyActiveMapStructure?.type === TimeTrackingMapStructureType.TRACK) {
        uniqueTrackIds.add(currentlyActiveMapStructure.trackId);
    }
    if (uniqueTrackIds.size === 0) {
        return mergeQueryData(order.trackIds, ids => {
            return query(Firebase.instance().getAllTracks({ companyId: undefined }), where("id", "in", ids));
        });
    } else {
        return mergeQueryData(Array.from(uniqueTrackIds), ids => {
            return query(Firebase.instance().getAllTracks({ companyId: undefined }), where("id", "in", ids));
        });
    }
}

export async function createAreaTaskRecord(
    order: Order,
    orderMachines: Machine[],
    currentlyActiveMapStructure: AnyTimeTrackingMapStructure | null
): Promise<TaskRecord> {
    const fields = await loadRelevantFields(order, currentlyActiveMapStructure);
    const areaUnit = getAreaUnit(order.serviceSnapshot);

    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);
    const currentlyActiveRunId = getCurrentlyActiveRunId(order);
    const currentlyActiveMachineVariants = constructMachineVariantsForNewOrderDataEntry(order, orderMachines);

    const calculatedArea = fields
        .filter(field => field.customerId === currentlyActiveCustomerId)
        .reduce((acc, cur) => {
            return acc + cur.areaHa;
        }, 0);

    return {
        id: v4(),
        record: round(areaUnit === ServicePriceUnit.HECTARE ? calculatedArea : calculatedArea * 10000, {
            decimalDigits: 2,
        }),
        timeStamp: new Date().toISOString(),
        unit: areaUnit ?? ServicePriceUnit.HECTARE,
        type: TaskRecordType.AREA,
        customerId: currentlyActiveCustomerId,
        orderRunId: currentlyActiveRunId ?? null,
        machineVariants: currentlyActiveMachineVariants,
    };
}

export async function createTracksTaskRecord(
    order: Order,
    orderMachines: Machine[],
    currentlyActiveMapStructure: AnyTimeTrackingMapStructure | null
): Promise<TaskRecord> {
    const tracks = await loadRelevantOrderTracks(order, currentlyActiveMapStructure);
    const tracksUnit = getTracksUnit(order.serviceSnapshot);
    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);
    const currentlyActiveRunId = getCurrentlyActiveRunId(order);
    const currentlyActiveMachineVariants = constructMachineVariantsForNewOrderDataEntry(order, orderMachines);

    const calculatedTracksLengthInMeters = tracks
        .filter(track => track.customerId === currentlyActiveCustomerId)
        .reduce((acc, cur) => {
            return acc + computeLength(cur.shape);
        }, 0);

    return {
        id: v4(),
        record: round(
            tracksUnit === ServicePriceUnit.KILOMETER
                ? calculatedTracksLengthInMeters / 1000
                : calculatedTracksLengthInMeters,
            { decimalDigits: 2 }
        ),
        timeStamp: new Date().toISOString(),
        unit: tracksUnit ?? ServicePriceUnit.KILOMETER,
        type: TaskRecordType.TRACKS,
        customerId: currentlyActiveCustomerId,
        orderRunId: currentlyActiveRunId ?? null,
        machineVariants: currentlyActiveMachineVariants,
    };
}

export function createTonsTaskRecord(order: Order, orderMachines: Machine[]): TaskRecord {
    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);
    const currentlyActiveRunId = getCurrentlyActiveRunId(order);
    const currentlyActiveMachineVariants = constructMachineVariantsForNewOrderDataEntry(order, orderMachines);

    return {
        id: v4(),
        record: null,
        timeStamp: new Date().toISOString(),
        unit: ServicePriceUnit.TONS,
        type: TaskRecordType.TONS,
        customerId: currentlyActiveCustomerId,
        orderRunId: currentlyActiveRunId ?? null,
        machineVariants: currentlyActiveMachineVariants,
    };
}

export function createCubicMetersTaskRecord(order: Order, orderMachines: Machine[]): TaskRecord {
    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);
    const currentlyActiveRunId = getCurrentlyActiveRunId(order);
    const currentlyActiveMachineVariants = constructMachineVariantsForNewOrderDataEntry(order, orderMachines);

    return {
        id: v4(),
        record: null,
        timeStamp: new Date().toISOString(),
        unit: ServicePriceUnit.CUBIC_METERS,
        type: TaskRecordType.CUBIC_METERS,
        customerId: currentlyActiveCustomerId,
        orderRunId: currentlyActiveRunId ?? null,
        machineVariants: currentlyActiveMachineVariants,
    };
}

export function createLiterTaskRecord(order: Order, orderMachines: Machine[]): TaskRecord {
    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);
    const currentlyActiveRunId = getCurrentlyActiveRunId(order);
    const currentlyActiveMachineVariants = constructMachineVariantsForNewOrderDataEntry(order, orderMachines);

    return {
        id: v4(),
        record: null,
        timeStamp: new Date().toISOString(),
        unit: ServicePriceUnit.LITER,
        type: TaskRecordType.LITER,
        customerId: currentlyActiveCustomerId,
        orderRunId: currentlyActiveRunId ?? null,
        machineVariants: currentlyActiveMachineVariants,
    };
}

export function createLoadsTaskRecord(order: Order, orderMachines: Machine[]): TaskRecord {
    const currentlyActiveCustomerId = getCurrentlyActiveCustomerId(order);
    const currentlyActiveRunId = getCurrentlyActiveRunId(order);
    const currentlyActiveMachineVariants = constructMachineVariantsForNewOrderDataEntry(order, orderMachines);

    return {
        id: v4(),
        record: null,
        timeStamp: new Date().toISOString(),
        unit: ServicePriceUnit.LOADS,
        type: TaskRecordType.LOADS,
        customerId: currentlyActiveCustomerId,
        orderRunId: currentlyActiveRunId ?? null,
        machineVariants: currentlyActiveMachineVariants,
    };
}
